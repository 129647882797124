html,
body {
  margin: 0;
}

.container {
  background-color: black;
  width: 100%;
  height: 100vh;
  display: flex;
}

.color {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  cursor: pointer;
  transition: 0.2s opacity;
}

.color:active {
  opacity: 0.8;
}

.color span {
  font-size: 3rem;
  margin-bottom: 2rem;
  color: white;
  text-shadow: 2px 2px black;
}

#form {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

#form input[type="text"] {
  font-size: 2rem;
  outline: 0;
  border: 2px solid white;
  background-color: #f2f2f2;
  padding: 20px;
  width: 400px;
}

.btn {
  background-color: #4caf50;
  padding: 20px;
  font-size: 2rem;
  color: white;
  border: 2px solid white;
  transition: 0.2s opacity;
}

.btn:hover {
  opacity: 0.8;
}


/* Only apply styles for screens with a maximum width of 768px (tablet and below) */
@media only screen and (max-width: 768px) {
  .container {
    flex-wrap: wrap; /* Allow flex items to wrap */
    align-content: flex-start; /* Align items at the start */
  }

  .color {
    width: 50%; /* Display two colors per row */
  }

  /* Smaller font size for hex color code on tablets */
  .color span {
    font-size: 1.5rem;
  }
}

/* Only apply styles for screens with a maximum width of 480px (mobile devices) */
@media only screen and (max-width: 480px) {
  .color {
    width: 100%; /* Display one color per row */
  }

  .color span {
    font-size: 2rem; /* Reduce font size */
    margin-bottom: 1rem; /* Reduce margin */
  }

  #form input[type="text"] {
    width: 80%; /* Adjust input width */
    font-size: 1.5rem; /* Reduce font size */
  }

  .btn {
    padding: 15px; /* Reduce padding */
    font-size: 1.5rem; /* Reduce font size */
  }

  /* Smaller font size for hex color code on mobile */
  .color span {
    font-size: 1rem;
  }

  /* Even smaller font size for white hex code */
  .color.white span {
    font-size: 1rem;
  }
}
